.app {
  font-family: 'Roboto', sans-serif;
}
.app h1, h2{
  text-align: center;
}

img {
  width: 100%;
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}

.content {
  margin-top: 55px;
}